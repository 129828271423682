.App {
  text-align: center;
  --primary: $primary;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  // box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.bg-graydient {
  background: radial-gradient(
    343px at 46.3% 47.5%,
    rgb(242, 242, 242) 0%,
    rgb(241, 241, 241) 72.9%
  );
}

.Header {
  position: absolute;
  top: 0;
  height: 60px;
  width: 100vw;
  z-index: 100;
}

.logo {
  height: 50px;
  padding-top: 10px;
}

.logo:hover {
  cursor: pointer;
}

.top-60 {
  top: 60px;
}

.coming-soon-img {
  margin-left: 30vw;
  margin-top: 25vh;
  height: 30vh;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  height: 100%;
  margin: 20px;
  background-color: white;
  border-radius: 10px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.navbutton-icon {
  height: 40px;
  color: #158c79;
}

.dashboard-container {
  width: 900px;
}

$primary: #00b894;
$danger: #e74c3c;
$warning: #f4a916;
$info: #eeeeee;

.c-primary{
  color: $primary !important;
}

.bg-primary{
  background-color: $primary;
  background: $primary;
}

.c-danger{
  color: $danger !important;
}

.c-warning{
  color: $warning !important;
}

.bg-danger{
  background-color: $primary;
  background: $primary;
}

.user-profile-container{
  width:50px;
  aspect-ratio: 1;
}

.user-profile-img{
  width:100%;
  height:100%;
  object-fit: cover;
  border-radius: 100%;
}

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/* override some bootstrap rules */
.form-floating > label::after {
    background-color: transparent !important;
}

/* Ensure the floating label is truncated if it overflows */
.form-floating > label {
  z-index: 0;
  display: block; /* Make sure the label is a block element */
  max-width: 100%; /* Ensures label width is limited to input width */
  white-space: nowrap; /* Prevents the label from wrapping onto multiple lines */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Adds an ellipsis (...) to indicate truncation */
  color: #607489;
}

/* make the toggle buttons white when checked */
.btn-check:checked + .btn{
  color:#fff;
}

/* Make the transparent background for the Confusion Error modal Prominent */
.modal-backdrop{
  --bs-backdrop-zindex: 9999;
}

/* Make the modal more prominent than the backdrop */
.modal.show{
  z-index: 10000 !important;
}

.modal{
  z-index: 10000 !important;
  --bs-modal-zindex: 10000;
}