h2 {
  font-size: 1.4em !important;
  font-weight: 500 !important;
}

h3 {
  font-size: 1.1em !important;
  font-weight: 500 !important;
}

.vl {
  border-left: 1px solid lightgray;
  height: 100%;
}

h1 {
  font-size: 1.6em !important;
  font-weight: 500 !important;
}

.is-invalid {
  margin-top: -5px;
  margin-left: 5px;
  margin-bottom: 10px;
  color: red;
}

strong {
  font-weight: 500 !important;
}

.dropdown-container {
  height: 58px !important;
  border-radius: 5px !important;
  padding-top: 8px;
}