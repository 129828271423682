Table {
  overflow-y: scroll;

  th {
    font-weight: 600;
  }
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: radial-gradient(
    343px at 46.3% 47.5%,
    rgb(242, 242, 242) 0%,
    rgb(241, 241, 241) 72.9%
  );
}

.installations-container {
  padding: 15px;
  margin-top: -15px;
}

.d-flex {
  gap: 20px;
}

tr .align-center {
  display: flex;
  align-items: center;
}

Bar {
  height: 300px !important;
}

